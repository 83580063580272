$breakpoints: (
  'phone': 480px,
  'phone-wide': 600px,
  'tablet': 768px,
  'tablet-wide': 944px,
  'desktop': 1264px,
  'desktop-wide': 1900px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
