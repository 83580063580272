#login-page {
  padding: 20px;
  text-align: center;
  padding-bottom: 200px;
  .actions {
    button {
      cursor: pointer;
      border: none;
      width: 200px;
      height: 50px;
      margin: 4px;
      font-size: 0.9em;
      color: white;
      border-radius: 4px;
      &.google {
        background-color: #fc2875;
      }
      &.microsoft {
        background-color: #6ae7dd;
      }
    }
  }
}
