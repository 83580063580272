@mixin layout() {
  padding-left: $navbarWidth + 20px;
  padding-right: 20px;
  &.withCalendar {
    padding-right: $calendarPanelWidth + 20px;
  }
  @include mq('tablet-wide') {
    padding-left: 20px;
    padding-right: 20px;
    &.withCalendar {
      padding-right: $calendarPanelWidth + 20px;
    }
  }
  @include mq('phone-wide') {
    padding-left: 0px;
    padding-right: 0px;
  }
}
