// @import '~gipsy-ui/lib/css/index.css';
@import '~gipsy-ui/lib/styles/variables.scss';
@import '~gipsy-ui/lib/styles/mixins.scss';
@import './variables.scss';
@import './mixins.scss';

.gipsy-ui .homebase {
  @import 'features/layout/index.scss';
  @import 'pages/login/index.scss';
  @import 'pages/notFound/index.scss';
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background: $bodyBackgroundColor;
  margin: 0;
  padding: 0;
}

body.noscroll {
  overflow: hidden;
}
