.container {
  &.connected {
    @include layout();
    padding-top: 20px;
  }
  margin: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
